import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import { rhythm, scale } from '../utils/typography';

import '../styles/styles.scss';

class Layout extends React.Component {
  render() {
    const { location, title, children, prev, next } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;

    return (
      <>
        {location.pathname == rootPath ? (
          <Helmet>
            <body className="index" />
          </Helmet>
        ) : null}
        <header>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <em>Fundamentals of Web Application Development · DRAFT</em>
          </Link>
          <em>Freeman</em>
        </header>
        <main>{children}</main>
        <footer>
          {prev ? (
            <Link to={prev.frontmatter.slug} rel="prev">
              ← {prev.frontmatter.title}
            </Link>
          ) : (
            <a />
          )}
          <Link to="/#contents">Contents</Link>
          {next ? (
            <Link to={next.frontmatter.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          ) : (
            <a />
          )}
        </footer>
      </>
    );
  }
}

export default Layout;
